<template>
<!--结果页面-->
    <div class="tools">
        <el-button @click="this.$router.back()">{{lang['html.button']}}</el-button>
        <el-tag type="info" style="margin-left: 10px"> {{lang['html.el-tag']}}: {{getTableNum}}</el-tag>
    </div>
    <el-table
        :data="this.tableData"
        style="width: 300%"
        v-loading=isLoading
        stripe
        border
    >
        <el-table-column label="id" width="90" highlight-current-row>
            <template #default="scope">
                {{ scope.row.id }}
            </template>
        </el-table-column>

        <el-table-column label="object"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.object }}
            </template>
        </el-table-column>

        <el-table-column label="state"  highlight-current-row>
            <template #default="scope">
                {{ getStateStr(scope.row.state) }}
            </template>
        </el-table-column>
    </el-table>

    <pagination-table-data
        :TableData = this.result.data
        @updateTableData = "updateTableData"
    />
</template>

<script>


import PaginationTableData from "../../components/PaginationTableData";
export default {
    name: "result",
    components: {PaginationTableData},
    data(){
        return {
            result:{
                title:'no title',//结果项目标题
                data:[],//待转换的表格源数据
                lang:[],//多语言
                langCodeField:'',//结果字段前缀
            },
            isLoading:true,
            lang:this.$lang.getLang('common','result'),
            tableData:[],
        }
    },
    computed:{
        //取表格数量
        getTableNum(){
            return this.result.data.length;
        }
    },
    methods:{
        //基于状态码取文本消息
        getStateStr(stateCode){
            let field = this.result.langCodeField + stateCode;
            let str = this.result.lang[field] == null ? '[?]' + stateCode : this.result.lang[field];
            return str;
        },
        updateTableData(obj){
            this.tableData = obj;
        }
    },
    mounted() {
        //取出存放在Vuex的数据
        let obj = this.$store.getters['common/get_result'];
        if (obj){
            this.$notify({
                message:this.lang['mounted.wait'],
                type:'info'
            });
            this.result = obj;
            this.result.data = obj.data;
            this.$message({
                    message:this.lang['mounted.success'] + ' [' + obj.title + ']',
                    type:'success'
                });
        }else{
            this.$alert(this.lang['mounted.fail'],'Error',{type:'error'});
        }
        this.isLoading = false;
        console.log('result.mounted',this.result)
    },
}
</script>

<style scoped>
.tools{
    margin-bottom: 5px;
    margin-right: 10px;
}

</style>